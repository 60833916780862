import { connect } from 'react-redux';
import { LoginPopup } from 'swag-client-common/components/popups/login-popup';
import { loginFailureCodeSelector } from 'swag-client-common/redux/user/user.selectors';
import { SENDOSO_ROLE_IS_NOT_VALID_FOR_REGISTER } from 'swag-common/constants/error-codes';
import { createSelector } from 'reselect';
import { isLoadingSelector, isLoggedInSelector } from '../../../redux/user/user.selectors';
const errorSelector = createSelector(loginFailureCodeSelector, errorCode => {
  if (errorCode === SENDOSO_ROLE_IS_NOT_VALID_FOR_REGISTER) {
    return 'User role "Sender" doesn\'t have permissions to access the store. ';
  }
  return '';
});
const isOpenSelector = createSelector(isLoggedInSelector, isLoadingSelector, (isLoggedIn, isLoading) => {
  if (isLoading) {
    return false;
  }
  return !isLoggedIn;
});
const mapStateToProps = state => {
  return {
    isOpen: isOpenSelector(state),
    isLoggedOut: state.user.isLoggedOut,
    error: errorSelector(state)
  };
};
export default connect(mapStateToProps)(LoginPopup);