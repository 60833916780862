import _omit from "lodash/omit";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { SENDOSO } from 'swag-common/constants/tenant.constants';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { OrderApi } from 'swag-client-common/api/order.api';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import TenantPayment from '../../api/tenant-payment.api';
import { getToken } from '../stripe-form/stripe-form.actions';
import TenantOrder from '../../api/tenant-order.api';
import { paymentDetailsSendosoFormSelector } from './supervisor-dashboard.selectors.sendoso';
const PREFIX = 'SUPERVISOR_DASHBOARD_';
export const GET_TENANT_ORDERS_LOADING = `${PREFIX}GET_TENANT_ORDERS_LOADING`;
export const GET_TENANT_ORDERS_SUCCESS = `${PREFIX}GET_TENANT_ORDERS_SUCCESS`;
export const GET_TENANT_ORDERS_ERROR = `${PREFIX}GET_TENANT_ORDERS_ERROR`;
const CARD_REMOVE_TEXT = 'Card has been successfully attached to account.';
const CARD_ATTACH_TEXT = 'Card has been successfully removed.';
export const getTenantOrders = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_TENANT_ORDERS_LOADING
    });
  },
  transformData: data => _omit(data, 'isAllOrdersPage'),
  fn: OrderApi.getTenantOrders,
  takeLatest: true,
  success: ({
    total,
    page,
    orders
  }, {
    statuses,
    search
  }, dispatch, {
    isAllOrdersPage
  }) => ({
    type: GET_TENANT_ORDERS_SUCCESS,
    payload: {
      page,
      total,
      orders,
      search,
      isAllOrdersPage,
      statuses
    }
  }),
  error: errorAction(GET_TENANT_ORDERS_ERROR),
  postSuccess: (dispatch, getState, _, {
    error
  }) => {
    error && dispatch(showNotification({
      text: error
    }));
  }
});
export const GET_TENANT_PAYMENT_INFO_LOADING = `${PREFIX}GET_TENANT_PAYMENT_INFO_LOADING`;
export const GET_TENANT_PAYMENT_INFO_SUCCESS = `${PREFIX}GET_TENANT_PAYMENT_INFO_SUCCESS`;
export const GET_TENANT_PAYMENT_INFO_ERROR = `${PREFIX}GET_TENANT_PAYMENT_INFO_ERROR`;
export const getTenantPaymentInfo = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: GET_TENANT_PAYMENT_INFO_LOADING
    });
  },
  fn: TenantPayment.getCardInfoByTenant,
  success: ({
    card
  }) => ({
    type: GET_TENANT_PAYMENT_INFO_SUCCESS,
    payload: {
      card
    }
  }),
  error: errorAction(GET_TENANT_PAYMENT_INFO_ERROR),
  postSuccess: (dispatch, getState, _, {
    error
  }) => {
    error && dispatch(showNotification({
      text: error
    }));
  }
});
export const REMOVE_TENANT_PAYMENT_INFO_LOADING = `${PREFIX}REMOVE_TENANT_PAYMENT_INFO_LOADING`;
export const REMOVE_TENANT_PAYMENT_INFO_SUCCESS = `${PREFIX}REMOVE_TENANT_PAYMENT_INFO_SUCCESS`;
export const REMOVE_TENANT_PAYMENT_INFO_ERROR = `${PREFIX}REMOVE_TENANT_PAYMENT_INFO_ERROR`;
export const removeTenantPaymentInfo = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REMOVE_TENANT_PAYMENT_INFO_LOADING
    });
  },
  fn: TenantPayment.removeTenantCardInfo,
  success: ({
    card
  }) => ({
    type: REMOVE_TENANT_PAYMENT_INFO_SUCCESS,
    payload: {
      card
    }
  }),
  error: errorAction(REMOVE_TENANT_PAYMENT_INFO_ERROR),
  postSuccess: (dispatch, getState, _, {
    error
  }) => {
    return error ? dispatch(showNotification({
      text: error
    })) : dispatch(showNotification({
      text: CARD_ATTACH_TEXT
    }));
  }
});
export const ADD_TENANT_PAYMENT_INFO_LOADING = `${PREFIX}ADD_TENANT_PAYMENT_INFO_LOADING`;
export const ADD_TENANT_PAYMENT_INFO_SUCCESS = `${PREFIX}ADD_TENANT_PAYMENT_INFO_SUCCESS`;
export const ADD_TENANT_PAYMENT_INFO_ERROR = `${PREFIX}ADD_TENANT_PAYMENT_INFO_ERROR`;
export const addTenantPaymentInfo = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: ADD_TENANT_PAYMENT_INFO_LOADING
    });
  },
  fn: TenantPayment.addTenantCardInfo,
  success: ({
    card
  }) => ({
    type: ADD_TENANT_PAYMENT_INFO_SUCCESS,
    payload: {
      card
    }
  }),
  error: errorAction(ADD_TENANT_PAYMENT_INFO_ERROR),
  postSuccess: (dispatch, getState, _, {
    error
  }) => {
    return error ? dispatch(showNotification({
      text: error
    })) : dispatch(showNotification({
      text: CARD_REMOVE_TEXT
    }));
  }
});
const sendRequest = (address, stripe, card, onTokenReceive) => dispatch => {
  dispatch(getToken(address, stripe, card, onTokenReceive));
};
export const addCard = (stripe, card) => (dispatch, getState) => {
  const state = getState();
  const values = paymentDetailsSendosoFormSelector(state);
  const {
      firstName,
      lastName
    } = values,
    rest = _objectWithoutProperties(values, ["firstName", "lastName"]);
  const address = _objectSpread({
    firstName,
    lastName
  }, rest);
  dispatch(sendRequest(address, stripe, card, payload => dispatch(addTenantPaymentInfo({
    cardToken: payload[0],
    firstName,
    lastName,
    tenantName: SENDOSO
  }))));
};
export const APPROVE_TENANT_ORDER_REQUEST = `${PREFIX}APPROVE_TENANT_ORDER_REQUEST`;
export const APPROVE_TENANT_ORDER_SUCCESS = `${PREFIX}APPROVE_TENANT_ORDER_SUCCESS`;
export const APPROVE_TENANT_ORDER_FAILURE = `${PREFIX}APPROVE_TENANT_ORDER_FAILURE`;
const ORDER_APPROVED_TEXT = 'Order has been successfully approved.';
const ORDER_REJECTED_TEXT = 'Order has been successfully rejected.';
export const approveTenantOrder = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: APPROVE_TENANT_ORDER_REQUEST
    });
  },
  fn: TenantOrder.approve,
  success: body => ({
    type: APPROVE_TENANT_ORDER_SUCCESS,
    payload: body
  }),
  error: APPROVE_TENANT_ORDER_FAILURE,
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(showNotification({
      text: ORDER_APPROVED_TEXT
    }));
  },
  postError: (dispatch, getState, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  }
});
export const REJECT_TENANT_ORDER_REQUEST = `${PREFIX}REJECT_TENANT_ORDER_REQUEST`;
export const REJECT_TENANT_ORDER_SUCCESS = `${PREFIX}REJECT_TENANT_ORDER_SUCCESS`;
export const REJECT_TENANT_ORDER_FAILURE = `${PREFIX}REJECT_TENANT_ORDER_FAILURE`;
export const rejectTenantOrder = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: REJECT_TENANT_ORDER_REQUEST
    });
  },
  fn: TenantOrder.reject,
  success: body => ({
    type: REJECT_TENANT_ORDER_SUCCESS,
    payload: body
  }),
  error: REJECT_TENANT_ORDER_FAILURE,
  postSuccess: dispatch => {
    dispatch(hidePopup());
    dispatch(showNotification({
      text: ORDER_REJECTED_TEXT
    }));
  },
  postError: (dispatch, getState, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  }
});