function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { TENANT_ORDER_APPROVAL_STATUS } from 'swag-common/constants/order-statuses.constants';
import { STATE_LOADING, STATE_ERROR, STATE_SUCCESS, STATE_IDLE } from '../redux.constants';
import { ADD_TENANT_PAYMENT_INFO_ERROR, ADD_TENANT_PAYMENT_INFO_LOADING, ADD_TENANT_PAYMENT_INFO_SUCCESS, GET_TENANT_ORDERS_ERROR, GET_TENANT_ORDERS_LOADING, GET_TENANT_ORDERS_SUCCESS, GET_TENANT_PAYMENT_INFO_ERROR, GET_TENANT_PAYMENT_INFO_LOADING, GET_TENANT_PAYMENT_INFO_SUCCESS, REMOVE_TENANT_PAYMENT_INFO_ERROR, REMOVE_TENANT_PAYMENT_INFO_LOADING, REMOVE_TENANT_PAYMENT_INFO_SUCCESS, APPROVE_TENANT_ORDER_SUCCESS, REJECT_TENANT_ORDER_SUCCESS, REJECT_TENANT_ORDER_FAILURE, APPROVE_TENANT_ORDER_FAILURE, REJECT_TENANT_ORDER_REQUEST, APPROVE_TENANT_ORDER_REQUEST } from './supervisor-dashboard.actions.sendoso';
const allOrdersStatuses = Object.values(TENANT_ORDER_APPROVAL_STATUS).filter(status => status !== TENANT_ORDER_APPROVAL_STATUS.PENDING);
const initialState = {
  allOrders: [],
  allOrdersStatuses,
  allOrdersSearch: '',
  allOrdersPage: 1,
  allOrdersTotal: 0,
  ordersToApprove: [],
  ordersToApproveSearch: '',
  ordersToApprovePage: 1,
  ordersToApproveTotal: 0,
  // @todo. auto generated. fix this error
  // @ts-ignore
  currentOrderToApprove: null,
  getOrdersEndpointState: STATE_LOADING,
  card: null,
  cardId: null,
  getCardInfoEndpointState: STATE_IDLE,
  approvalTenantOrderState: STATE_IDLE
};
export default function supervisorDashboard(state = initialState, {
  type,
  payload
}) {
  switch (type) {
    case GET_TENANT_ORDERS_LOADING:
      {
        return _objectSpread({}, state, {
          getOrdersEndpointState: STATE_LOADING
        });
      }
    case GET_TENANT_ORDERS_ERROR:
      {
        return _objectSpread({}, state, {
          getOrdersEndpointState: STATE_ERROR
        });
      }
    case GET_TENANT_ORDERS_SUCCESS:
      {
        const {
          page,
          total,
          orders,
          isAllOrdersPage,
          statuses,
          search
        } = payload;
        const nextState = _objectSpread({}, state, {
          getOrdersEndpointState: STATE_SUCCESS
        });
        if (isAllOrdersPage) {
          nextState.allOrdersTotal = total;
          nextState.allOrdersPage = page;
          nextState.allOrders = orders;
          nextState.allOrdersStatuses = statuses;
          nextState.allOrdersSearch = search;
        } else {
          nextState.ordersToApproveTotal = total;
          nextState.ordersToApprovePage = page;
          nextState.ordersToApprove = orders;
          nextState.ordersToApproveSearch = search;
        }
        return nextState;
      }
    case REMOVE_TENANT_PAYMENT_INFO_LOADING:
    case ADD_TENANT_PAYMENT_INFO_LOADING:
    case GET_TENANT_PAYMENT_INFO_LOADING:
      {
        return _objectSpread({}, state, {
          getCardInfoEndpointState: STATE_LOADING
        });
      }
    case ADD_TENANT_PAYMENT_INFO_SUCCESS:
    case GET_TENANT_PAYMENT_INFO_SUCCESS:
    case REMOVE_TENANT_PAYMENT_INFO_SUCCESS:
      {
        return _objectSpread({}, state, {
          getCardInfoEndpointState: STATE_SUCCESS,
          card: payload.card,
          cardId: payload.card ? payload.card.cardId : null
        });
      }
    case GET_TENANT_PAYMENT_INFO_ERROR:
    case REMOVE_TENANT_PAYMENT_INFO_ERROR:
    case ADD_TENANT_PAYMENT_INFO_ERROR:
      {
        return _objectSpread({}, state, {
          getCardInfoEndpointState: STATE_ERROR
        });
      }
    case REJECT_TENANT_ORDER_REQUEST:
    case APPROVE_TENANT_ORDER_REQUEST:
      {
        return _objectSpread({}, state, {
          approvalTenantOrderState: STATE_LOADING
        });
      }
    case REJECT_TENANT_ORDER_SUCCESS:
    case APPROVE_TENANT_ORDER_SUCCESS:
      {
        return _objectSpread({}, state, {
          ordersToApprove: state.ordersToApprove.filter(
          // @ts-ignore
          order => order._id !== payload.order._id),
          approvalTenantOrderState: STATE_SUCCESS
        });
      }
    case REJECT_TENANT_ORDER_FAILURE:
    case APPROVE_TENANT_ORDER_FAILURE:
      {
        return _objectSpread({}, state, {
          approvalTenantOrderState: STATE_ERROR
        });
      }
    default:
      return state;
  }
}