import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/tenant';
const TenantPayment = createApi({
  getCardInfoByTenant: {
    url: `${baseUrl}/card/:tenantName`,
    method: 'get'
  },
  removeTenantCardInfo: {
    url: `${baseUrl}/card`,
    method: 'delete'
  },
  addTenantCardInfo: {
    url: `${baseUrl}/card`,
    method: 'put'
  }
});
export default TenantPayment;