import _isValid from "redux-form/es/isValid";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { PAYMENT_DETAILS_SENDOSO, PAYMENT_SETTINGS } from 'swag-client-common/constants';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
import { getTenantInvoiceLinkByS3Invoice } from 'swag-common/services/links/get-download-link-from-s3.service';
import { pathSelector, subPathSelector } from '../common/common.selectors';
import { STATE_LOADING } from '../redux.constants';
export const rootSelector = state => state.supervisorDashboard;
export const allOrdersSelector = createSelector(rootSelector, state => {
  const formattedOrders = state.allOrders.map(order => _objectSpread({}, order, {
    tenantInvoiceLink: getTenantInvoiceLinkByS3Invoice(order.tenantInvoiceLink),
    tenantInvoiceFilename: `INV-${order.numWithoutPrefix}.pdf`
  }));
  return formattedOrders;
});
export const allOrdersStatusesSelector = createSelector(rootSelector, state => state.allOrdersStatuses);
export const allOrdersSearchSelector = createSelector(rootSelector, state => state.allOrdersSearch);
export const allOrdersPageSelector = createSelector(rootSelector, state => state.allOrdersPage);
export const allOrdersTotalSelector = createSelector(rootSelector, state => state.allOrdersTotal);
export const cardSelector = createSelector(rootSelector, state => state.card);
export const ordersToApproveSelector = createSelector(rootSelector, state => state.ordersToApprove);
export const ordersToApproveSearchSelector = createSelector(rootSelector, state => state.ordersToApproveSearch);
export const ordersToApprovePageSelector = createSelector(rootSelector, state => state.ordersToApprovePage);
export const ordersToApproveTotalSelector = createSelector(rootSelector, state => state.ordersToApproveTotal);
export const ordersToApproveStatusesSelector = () => [];
export const currentOrderToApproveSelector = createSelector(rootSelector, state => state.currentOrderToApprove);
export const cardIdSelector = createSelector(rootSelector, state => state.cardId);
export const getOrdersEndpointStateSelector = createSelector(rootSelector, state => state.getOrdersEndpointState);
export const isAllOrdersPageLoadingSelector = createSelector(getOrdersEndpointStateSelector, endpointState => endpointState === STATE_LOADING);
export const isOrdersToApprovePageLoadingSelector = createSelector(getOrdersEndpointStateSelector, endpointState => endpointState === STATE_LOADING);
export const getCardInfoEndpointStateSelector = state => state.supervisorDashboard.getCardInfoEndpointState;
export const isPaymentInfoLoadingSelector = createSelector(getCardInfoEndpointStateSelector, endpointState => endpointState === STATE_LOADING);
export const paymentDetailsSendosoFormSelector = createSelector(state => state.form[PAYMENT_DETAILS_SENDOSO], form => form ? form.values : {});
export const isCardExistSelector = createSelector(cardSelector, Boolean);
export const isTenantCardInfoValid = state => _isValid(PAYMENT_DETAILS_SENDOSO)(state);
export const currentSupervisorDashboardRouteSelector = createSelector(pathSelector, subPathSelector, (path, subPath) => {
  if (path.includes('supervisor-dashboard')) {
    return subPath;
  }
});
export const isLoadingSelector = createSelector(currentSupervisorDashboardRouteSelector, isPaymentInfoLoadingSelector, isOrdersToApprovePageLoadingSelector, (currentTab, isPaymentInfoLoading, isOrdersToApprovePageLoading) => currentTab === PAYMENT_SETTINGS ? isPaymentInfoLoading : isOrdersToApprovePageLoading);
export const initialValuesSelector = createSelector(cardSelector, card => {
  let updatedCard = {
    country: COUNTRY_CODES.US,
    state: ''
  };
  if (card) {
    const {
      name,
      country,
      address,
      city,
      expireMonth,
      expireYear,
      last4,
      state: countryState = '',
      zip,
      lastName,
      firstName
    } = card;
    const [street, unit] = address.split(',');
    updatedCard = {
      city,
      unit: unit ? unit.trim() : '',
      state: countryState ? countryState.trim() : '',
      street: street ? street.trim() : '',
      country,
      lastName,
      firstName,
      cvc: '***',
      holder: name,
      zipcode: zip,
      number: `**** **** **** ${last4}`,
      expDate: `${expireMonth} / ${getExpireDate(expireYear)}`
    };
  }
  return updatedCard;
});
const getExpireDate = date => +String(date).slice(-2);
export const approvalOrderIdSelector = state => state.popupManager.params.id;
export const isApprovalTenantOrderStateLoadingSelector = createSelector(rootSelector, root => root.approvalTenantOrderState === STATE_LOADING);