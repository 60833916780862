import { createSelector } from 'reselect';
import { isResellerInInvoiceModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { isOrderApprovalFlowSelector } from '../user/user.selectors';
import { isSomeSuperSpeedItemsSelector } from '../cart/cart.selectors';
import { isWarehouseAllowedForDeliverySelector } from '../inventory/inventory-other.selectors';
import { getRequiredFields } from './checkout.utils';
export const acceptContractSelector = () => true;
export const deliverToInventoryValidSelector = () => true;
export const listWithStoresSelector = () => [];
const rootSelector = state => state.checkout;
export const deliverySelector = createSelector(rootSelector, checkout => checkout.delivery);
export const isDeliveryOptionWasChosenSelector = createSelector(rootSelector, checkout => checkout.deliveryOptionWasChosen);
export const widgetIsOpenSelector = createSelector(rootSelector, checkout => checkout.atiWidgetIsOpen);
export const isDeliveryAddressValidSelector = createSelector(widgetIsOpenSelector, isDeliveryOptionWasChosenSelector, deliverToInventoryValidSelector, deliverySelector, isOrderApprovalFlowSelector, isSomeSuperSpeedItemsSelector, isWarehouseAllowedForDeliverySelector, isResellerInInvoiceModeSelector, (widgetIsOpen, isOptionWasChosen, toFulfillmentCenter, delivery, isOrderApprovalFlow, isSomeSuperSpeedItems, isWarehouseAllowedForDelivery, isResellerInInvoiceMode) => {
  if (widgetIsOpen) {
    const {
      fname,
      lname,
      email,
      company,
      phoneNumber
    } = delivery;
    const requiredValues = [isOptionWasChosen, fname, lname, email, company];
    if (!isResellerInInvoiceMode) {
      requiredValues.push(toFulfillmentCenter);
    }
    if (isSomeSuperSpeedItems) {
      requiredValues.push(phoneNumber);
    }
    return requiredValues.every(v => Boolean(v));
  }
  const requiredFields = getRequiredFields(delivery);
  if (isOrderApprovalFlow) {
    requiredFields.push(delivery.companyDepartmentId);
  }
  if (isSomeSuperSpeedItems) {
    requiredFields.push(delivery.phoneNumber);
  }
  const allRequiredFieldsValid = requiredFields.every(v => Boolean(v));
  if (!isWarehouseAllowedForDelivery) {
    return allRequiredFieldsValid;
  }
  return allRequiredFieldsValid && isOptionWasChosen;
});
export const resellersCommissionSelector = () => 0;
export const productsCommissionSelector = () => [];