import { createApi } from 'swag-client-common/libs/rapi';
const baseUrl = '/api/v1/order';
const TenantOrderApi = createApi({
  approve: {
    url: `${baseUrl}/tenant/approve/:id`,
    method: 'put'
  },
  reject: {
    url: `${baseUrl}/tenant/reject/:id`,
    method: 'put'
  }
});
export default TenantOrderApi;