function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { OrderApi } from 'swag-client-common/api/order.api';
import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { SHOW_ERROR_POPUP, CART_ORDER_VERSION_ERROR_TYPE } from 'swag-client-common/constants';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { CartErrorCodes } from 'swag-common/constants/error-codes';
import { loggedInUserIdSelector } from 'swag-client-common/redux/user/user.selectors';
import { orderTokenSelector, orderVersionSelector } from '../cart/cart.selectors';
import { replacementCommentSelector, replacementIsSilentSelector, replacementPriceRangeSelector, selectedItemIdSelector } from './checkout.selectors';
import { PREFIX, proceed, PROCEED_ORDER_SUCCESS, PROCEED_ORDER_FAIL, startLoading } from './checkout.actions';
export const SAVE_FUNDING_SOURCE = `${PREFIX}SAVE_FUNDING_SOURCE`;
export const saveFundingSource = value => ({
  type: SAVE_FUNDING_SOURCE,
  payload: value
});
export const proceedInventory = () => (dispatch, getState) => {
  const state = getState();
  const fundingSource = state.checkout.fundingSource;
  const {
    fname,
    lname
  } = state.checkout.delivery;
  return dispatch(proceed({
    paymentData: {
      firstName: fname,
      lastName: lname,
      fundingSource,
      savePaymentDetails: false
    }
  }));
};
export const GET_FUNDING_SOURCE_REQUEST = `${PREFIX}GET_FUNDING_SOURCE_REQUEST`;
export const GET_FUNDING_SOURCE_SUCCESS = `${PREFIX}GET_FUNDING_SOURCE_SUCCESS`;
export const GET_FUNDING_SOURCE_ERROR = `${PREFIX}GET_FUNDING_SOURCE_ERROR`;
export const getFundingSource = createAsyncAction({
  preFn: dispatch => dispatch({
    type: GET_FUNDING_SOURCE_REQUEST
  }),
  fn: OrderApi.getFundingSource,
  success: body => ({
    type: GET_FUNDING_SOURCE_SUCCESS,
    payload: body.data
  }),
  error: error => ({
    type: GET_FUNDING_SOURCE_ERROR,
    error
  })
});
export const proceedDummyOrder = createAsyncAction({
  transformData: (data, getState) => {
    const state = getState();
    return _objectSpread({}, data, {
      orderVersion: orderVersionSelector(state),
      userId: loggedInUserIdSelector(state)
    });
  },
  preFn: dispatch => dispatch(startLoading()),
  fn: OrderApi.proceedSendosoDummyOrder,
  success: body => {
    localStorage.setItem('isOrderTokenShouldBeRemoved', JSON.stringify(true));
    return {
      type: PROCEED_ORDER_SUCCESS,
      body
    };
  },
  error: errorAction(PROCEED_ORDER_FAIL),
  postError: (dispatch, getState, error) => {
    if (error.code === CartErrorCodes.INVALID_ORDER_VERSION) {
      dispatch(showPopup({
        popup: SHOW_ERROR_POPUP,
        type: CART_ORDER_VERSION_ERROR_TYPE,
        errorMessage: error.message
      }));
    }
  }
});
export const proceedReplacementOrder = createAsyncAction({
  transformData: (data, getState) => {
    const state = getState();
    const userId = loggedInUserIdSelector(state);
    const orderToken = orderTokenSelector(state);
    const orderVersion = orderVersionSelector(state);
    const originalItemId = selectedItemIdSelector(state);
    const replacementRange = Number(replacementPriceRangeSelector(state));
    const comment = replacementCommentSelector(state);
    const isSilent = replacementIsSilentSelector(state);
    return _objectSpread({}, data, {
      orderToken,
      orderVersion,
      userId,
      originalItemId,
      replacementRange,
      comment,
      isSilent
    });
  },
  preFn: dispatch => dispatch(startLoading()),
  fn: OrderApi.proceedSendosoReplacement,
  success: body => {
    return {
      type: PROCEED_ORDER_SUCCESS,
      body
    };
  },
  error: errorAction(PROCEED_ORDER_FAIL),
  postError: (dispatch, getState, error) => {
    if (error.code === CartErrorCodes.INVALID_ORDER_VERSION) {
      dispatch(showPopup({
        popup: SHOW_ERROR_POPUP,
        type: CART_ORDER_VERSION_ERROR_TYPE,
        errorMessage: error.message
      }));
    }
  }
});