import { createSelector } from 'reselect';
import { userHasInventoriesSelector } from 'swag-client-common/redux/user/user.selectors';
import { cartItemsSelector } from './inventory.selectors';
export const inventoryAddressTextSelector = () => 'All of your products will be shipped to the Phoenix fulfillment center';
export const shouldShowDeliveryLabelSelector = state => !state.checkout.deliverToFulfillmentCenter;
export const restrictionTooltipMessagesSelector = () => ['Please remove the sample product from your order.\n Samples cannot be added to the Phoenix fulfillment center'];
export const isNotInventoryLabelVisibleSelector = () => false;
export const showChangeDeliveryAddressButtonSelector = createSelector(cartItemsSelector, items => !!items.find(item => item.isSample === true || item.isPrintedSample === true));
export const isWarehouseAllowedSelector = () => true;
export const isWarehouseAllowedForDeliverySelector = () => true;
export const showInventoryLinkSelector = createSelector(userHasInventoriesSelector, isWarehouseAllowedSelector, (userHasInventories, isWarehouseAllowed) => {
  return userHasInventories || isWarehouseAllowed;
});