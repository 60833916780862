import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import sendosoBlackImg from 'assets/svg/sendoso-black.svg';
import sendosoWhiteImg from 'assets/svg/sendoso-white.svg';
import { SwagLogo as SwagLogoComponent, Color } from './swag-logo';
const logoLinkSelector = createSelector((state, props) => props.color, color => {
  if (color === Color.white) {
    return sendosoWhiteImg;
  }
  if (color === Color.black) {
    return sendosoBlackImg;
  }
  return '';
});
const mapStateToProps = () => createStructuredSelector({
  logoLink: logoLinkSelector,
  imgAlt: () => 'Sendoso'
});
export const SwagLogo = connect(mapStateToProps)(SwagLogoComponent);